import React from "react"
import styled from "styled-components"
import { rgba } from "polished"
import { Container, Row, Col } from "react-bootstrap"

import { Title, Section, Text, Span } from "../../../components/Core"
import { device } from "../../../utils"
import MainImage from "../../../assets/image/jpeg/active-acne.jpg"

const ImgContainer = styled.div`
  margin-top: 20px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`

const Newbut = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: auto;
  margin-bottom: 4em;
  padding-top: 1em;
  padding-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.4;

  color: #fff;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  max-width: 100%;
  display: inline-block;
`

const Iwrap = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
`

const Iline = styled.div`
  width: 6em;
  height: 1px;
  margin-right: 1em;
  background-color: rgb(171, 142, 102);

  @media screen and (max-width: 991px) {
    width: 3em;
  }
`

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span {
    color: #00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`

const AcneCausesPage = () => (
  <>
    {/* <!-- Content section 1 --> */}

    <Section bg="#fff" py={4}>
      <Container className="pb-3 pt-3">
        <Row className="align-items-center">
          <Col lg="12" className="order-lg-1 mt-lg-0  pb-lg-5">
            <div
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Iwrap>
                  <Itext as="h2">
                    Acne / Active Acne <span>London</span>
                  </Itext>
                </Iwrap>
                <SecondText className="pb-4">
                  Symptoms and Causes of Acne
                </SecondText>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col lg="12">
            <Text>
              Acne is caused by clogged hair follicles, which are small pores in
              the skin. Sebaceous glands are small glands that are located near
              the skin's surface. Hair follicles are little pores in your skin
              where a single hair grows out of, and the glands are related to
              them.
              <br /> <br />
              To keep the hair and skin from drying out, sebaceous glands
              lubricate them. This is accomplished through the production of
              sebum, an oily material.Acne occurs when sebaceous glands create
              excessive amounts of sebum. Excess sebum combines with dead skin ,
              forming a follicular blockage.
              <br /> <br />
              A whitehead forms when a blocked follicle is close to the skin's
              surface and bulges outwards. The clogged follicle might also be
              open to the skin, resulting in a blackhead. Normal skin bacteria
              can subsequently contaminate and infect the clogged follicles,
              resulting in papules, pustules, nodules, or cysts.
              <br /> <br />
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text>
              The severity of your acne can determine what type of treatment you
              should look out for and receive.
              <br /> <br />
              Acne can often be categorized as:
              <br /> <br />
              <b>Mild</b>: with a few papules and pustules, predominantly
              whiteheads and blackheads. (A papule is a little red mound on the
              skin, while a pustule is a small bump that contains pus or fluid
              on the skin)
              <br /> <br />
              <b>Moderate</b>: numerous papules and pustules, with many more
              whiteheads and blackheads
              <br /> <br />
              <b>Severe</b>: a lot of big, painful papules, pustules, nodules,
              or cysts, as well as possible scarring.
              <br /> <br />
              <b>Signs of acne</b>:<br /> <br />
              → Excessive oily skin
              <br />
              → Pustules <br />
              → Papules
              <br />
              → Whiteheads
              <br />
              → Blackheads <br />
              → Cysts or nodules
              <br />
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default AcneCausesPage
